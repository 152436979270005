@import "../vars.scss";

.mainMenuItem {
  height: 100%;
}

@media only screen and (min-width: 1022px) {
  .mainMenuItem:hover {
    background-color: transparent !important;
    border-bottom: 5px solid white;
    transform: translateY(2.5px);
  }
}

.logo:hover {
  border: 0 solid;
}
@media only screen and (max-width: 770px) {
  .intro-image {
    height: 400px;
  }
}
@media only screen and (max-width: 453px) {
  .intro-image {
    height: 500px;
  }
}
@media only screen and (max-width: 320px) {
  .intro-image {
    height: 600px;
  }
}
.navbar {
  height: 6rem;
  padding-top: 15px;
  color: white !important;
}

a {
  color: white !important;
}

@media only screen and (max-width: 1022px) {
  a {
    color: black !important;
  }
}

.logo {
  background-size: auto;
  padding-top: 5px;
  background: url("logo_white.png") no-repeat;
  width: 140px;
  height: 140px;
}

.navbar-dropdown a {
  color: black !important;
}
.navbar-dropdown {
  .navbar-item:hover {
    background-color: #9f9f9f !important;
  }
}

.intro-image {
  background: url("intro_image.png") no-repeat;
  background-size: cover;
  height: 600px;
  position: absolute;
  top: 0;
  width: 100%;
}

.green-background {
  background-color: $biox-primary-color !important;
  @include biox-shadow;
}