.MicroscopeDecoration {
  background-color: #D0C9C3;
  border-radius: 90%;
  width: 25vw;
  height: 25vw;
  position: absolute;
  right: 100px;
  transform: translate(0,-20vw);
  box-shadow: 0 0 15px 0 rgba(208,201,195,1);
  .spirulina {
    position: absolute;
  }
  .spirulina1 {
    width: 15vw;
    height: 15vw;
    margin-top: 2vw;
    z-index: 9;
  }
  .spirulina2 {
    width: 10vw;
    height: 10vw;
    margin-top: 10vw;
    margin-left: 9vw;
  }
  .spirulina3 {
    width: 13vw;
    height: 13vw;
    margin-top: 15vw;
    margin-left: 15vw;
  }
  .spirulina4 {
    width: 13vw;
    height: 13vw;
    margin-top: 10vw;
    margin-left: 2vw;
  }
}
@media only screen and (max-width: 1000px) {
  .MicroscopeDecoration {
    display: none;
  }
}