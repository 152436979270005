.Contact {
  padding-top: 70px;
  padding-bottom: 70px;
  .separator-title {
    h1 {
      text-align: center;
      padding: 0;
      border-bottom: 8px #589E44 solid;
    }
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
  .message-body {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 800px) {
  .Contact {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@media only screen and (max-width: 800px) {
  .Contact {
    padding-left: 50px;
    padding-right: 50px;
  }
}