.Article {
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: 50vh;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .container {
    width: 80vw;
  }
  h1 {
    font-size: 30px;
    line-height: 0.9;
    margin-bottom: 13px;
  }
}