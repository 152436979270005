@import "../../vars";

.ProjectCard {
  display: flex;
  justify-content: center;
  position: relative;
  .image {
    position: absolute;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    height: 230px;
    background-size: cover;
    z-index: 2;
  }
  .content {
    padding: 240px 10px 10px;
    width: 100%;
    border-radius: 25px;
    min-height: 25rem;
    position: relative;
    @include biox-shadow;
    a {
      position: absolute;
      right: 0;
      bottom: 0;
      padding-right: 20px;
      padding-bottom: 15px;
      color: #589E44 !important;
    }
    h1 {
      font-size: 25px;
      font-weight: normal;
    }
    p {
      line-height: 1.2;
      height: 3.5rem;
      overflow: hidden;
    }
    .fade {
      position: relative;
      height: 3.6em; /* exactly three lines */
    }
    .fade:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 70%;
      height: 1.2em;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
    .non-fade {
      height: 100px;
    }
    .imageShowcase {
      display: flex 1 0;
      justify-content: center;
      width: inherit;
    }
  }
}