.ContactLists, .ContactListsMobile {
  .right {
    width: 400px;
  }
  .faIcon {
    position: absolute;
    transform: translate(-23px, 3px);
  }
  @media only screen and (max-width: 800px) {
    .right {
      display: none;
    }
    .col {
      width: 100%;
      text-align: center;
    }
  }
}

.ContactListsMobile {
  margin-top: 30px;
  margin-left: 15px;
  .column {
    width: 190px;
  }
}

@media only screen and (min-width: 800px) {
  .ContactListsMobile {
    display: none !important;
  }
}