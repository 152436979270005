@import '../../vars';

.FeaturePoint {
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    padding: 10px;
    background-color: $biox-primary-color;
    border-radius: 50%;
  }
  h1 {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
  }
  p {
    padding-top: 10px;
    text-align: center;
    line-height: 1.2;
  }

}