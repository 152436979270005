@charset "utf-8";

// Roboto font
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'vars';

// Override bulma variables with brand colors
$turquoise: $biox-primary-color;
$cyan: rgba(0, 0, 0, 0);
$family-sans-serif: 'Roboto', sans-serif;

// Import bulma stylesheet
@import "~bulma/bulma.sass";

