@import "../vars.scss";

.Footer {
  color: white !important;
  height: 300px;
  background-color: $biox-primary-color;
  background-image: url("./footer_bg.png") !important;
  background-size: cover;
  box-shadow: 0px -5px 12px -1px rgba(0,0,0,0.5);
  a {
    color: white !important;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 100px;
  }
  .horizontal-center {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .col {
    .copyright {
      margin-top: 25px;
    }
  }
  .right {
    width: 400px;
  }
  .faIcon {
    position: absolute;
    transform: translate(-23px, 3px);
  }
  @media only screen and (max-width: 800px) {
    .right {
      display: none;
    }
    .col {
      width: 100%;
      text-align: center;
    }
  }
}