.Projects {
  .separator-title {
    h1 {
      text-align: center;
      padding: 0;
      border-bottom: 8px #589E44 solid;
    }
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
  padding: 50px;
}