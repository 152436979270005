@import "../vars";

.Home {
  section {
    margin-bottom: 5rem;
  }
  .section-intro {
    .scrim {
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5rem rgba(0, 0, 0, 0.5);
    }
    h2 {
      max-width: 35rem;
    }
    nav {
      padding: 30px;
    }
  }

  .separator-title {
    h1 {
      text-align: center;
      padding: 0;
      border-bottom: 8px #589E44 solid;
    }
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
  .section-projects {
    .columns {
      padding: 20px;
    }
  }
  .section-partners {
    .column {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;
    }
  }
}